<template>
  <body class="auth-wrapper container">
    <div class="all-wrapper menu-side with-pattern">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Change Password</h3>
        <h6 style="padding-bottom: 20px;">
          Its been 90 days since you last changed your password
        </h6>
        <a-form
          layout="vertical"
          :model="form"
          :rules="validationRules()"
          name="login"
          class="user-form"
          @finish="handleOk"
        >
          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="old_password" autocomplete="off">
                <a-input-password
                  v-model:value="form.old_password"
                  placeholder="Current Password"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="password" autocomplete="off">
                <a-input-password
                  :visibilityToggle="true"
                  v-model:value="form.password"
                  placeholder="Enter New Password"
                  @change="validPasswordPattern(form.password)"
                >
                </a-input-password>
                <div v-if="this.errors.length">
                  <span style="color:red" v-for="error in errors" :key="error">
                    {{ error }}
                  </span>
                </div>
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="confirmPassword" autocomplete="off">
                <a-input-password
                  :visibilityToggle="true"
                  v-model:value="form.confirmPassword"
                  placeholder="Confirm Password"
                />
              </a-form-item>
            </div>
          </div>
          <footer class="modal-footer">
            <slot name="footer">
              <a-button
                type="primary"
                size="large"
                html-type="submit"
                class="btn-block"
              >
                <span>Change Password</span>
              </a-button>

              <!-- <div class="buttons-w">
                <router-link to="/logout">
                  <small>Logout </small>
                </router-link>
              </div> -->

              <a-button
                type="secondry"
                size="medium"
                class="buttons-w"
                @click="this.clearState"
                style="display: block; margin: auto;"
              >
                <span>Logout</span>
              </a-button>
            </slot>
          </footer>
        </a-form>
      </div>
    </div>
  </body>
</template>

<script>
import logo from '../../assets/img/assembly-cp-logo.png';
import { ref, defineComponent, defineEmits } from 'vue';
import { mapActions } from 'vuex';
import router from 'src/router';
export default defineComponent({
  data() {
    return {
      form: {
        old_password: '',
        password: '',
        confirmPassword: '',
      },
      errors: [],
      passwordErrorMessage: '',
    };
  },
  emits: ['close'],
  setup() {
    return {
      logo,
    };
  },

  methods: {
    ...mapActions(['RequestChangePassword', 'userLogout', ' clearState']),
    async validPassword(_, password) {
      if (password && password === this.form.password) {
        return Promise.resolve();
      } else {
        return Promise.reject('Confirm password must be equal to password');
      }
    },
    async validPasswordPattern(password) {
      this.errors = [];
      if (password.length < 9) {
        this.errors.push('Your password must be at least 9 characters. ');
      }
      if ((password.match(/[A-Z]/g) || []).length < 1) {
        this.errors.push(
          'Your password must contain at least one capital letter.'
        );
      }
      if ((password.match(/[0-9]/g) || []).length < 2) {
        this.errors.push('Your password must contain at least two numbers. ');
      }
      if ((password.match(/[^\w\s]/g) || []).length < 2) {
        this.errors.push(
          'Your password must contain at least two Special characters. '
        );
      }
      if (this.errors.length > 0) {
        return false;
      }
      return Promise.resolve();
    },
    validationRules() {
      return {
        old_password: [
          {
            required: true,
            message: 'Current password is required',
          },
        ],
        password: [
          {
            required: true,
            min: 9,
            // validator: this.validPasswordPattern,
            trigger: 'submit',
            // message: 'Password should contain eight characters, at least one uppercase letter, one lowercase letter and one number',
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: this.validPassword,
            trigger: 'submit',
            message: 'Confirm password field is required',
          },
        ],
      };
    },
    async handleOk() {
      this.confirmLoading = true;
      const payload = {
        old_password: this.form.old_password,
        password: this.form.password,
        confirm_password: this.form.confirmPassword,
      };
      const isChanged = await this.RequestChangePassword(payload);
      if (isChanged) {
        router.replace({ path: '/' });
      }
    },
  },
});
</script>
<style scoped>
.container {
  height: 100vh;
}

.auth-form {
  max-width: 510px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.reset-password-form {
  width: 90%;
  margin-top: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.header-icon {
  font-size: 60px;
  color: #047bf8;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
  margin-left: -12px;
}
.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}

.user-form input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
